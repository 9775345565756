import { registerSW } from 'virtual:pwa-register'
import {offlineReady, updatePending} from "./stores";

export const updateSW = registerSW({
    onNeedRefresh() {
        updatePending.set(true)
        // if (confirm("App update available. Reload?")) {
        //     updateSW(true);
        // }
    },
    onOfflineReady() {
        offlineReady.set(true)
    },
})