<script>
    import Navbar from "../components/Navbar.svelte";
    import {apiGetStudentInfo, logout} from "../utils/api.ts";
    import MIcon from "../components/MIcon.svelte";
    import UpdateMeButton from "../components/UpdateMeButton.svelte";
    import {_icon_literal} from "../utils/icon.js";
    import {_} from "svelte-i18n";
    // import tg from "../res/telegram.svg";
    import Telegram from "../res/Telegram.svelte";
    import {scheduleCache} from "../stores.ts";
    import ShareModal from "../components/ShareModal.svelte";
    import AboutModal from "../components/AboutModal.svelte";
    import Heart from "../res/Heart.svelte";
    import { navigate } from "svelte-routing";

    const __unused = _icon_literal("check")

    let selectedProtocol = 'RS232';
    let ip;
    let error = null;
    let submitting = false;
    let ipIsValid = true;

    function validateIP(event) {
        const value = event.target.value;
        const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
        const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

        ipIsValid = ipv4Regex.test(value) || ipv6Regex.test(value);
    }

    async function submit() {
        submitting = true;
        try {
            // await set_settings(ip);
        } catch (err) {
            error = err;
        } finally {
            submitting = false;
        }
    }

    const dataPromise = apiGetStudentInfo();
</script>

<Navbar pageName={$_('page.info.nav', {default: 'Info'})}>
    <ShareModal/>
    <AboutModal/>
    <div>
        {#await dataPromise}
            <progress class="circle large"></progress>
        {:then student}
            <div class="row">
                <img class="circle large" src="https://lks.volgatech.net/Images/StudentPhoto/{student.lkPhoto}">
                <div class="max">
                    <h6>{student.firstName}</h6>
                    <p>{student.middleName} {student.lastName}</p>
                </div>
            </div>
            <h6>{student.academicStateName}</h6>
        {:catch error}
            <div class="row">
                <MIcon class="orange-text">warning</MIcon>
                <div class="max">{$_('error', {default: 'Error: '})}{error.message}</div>
            </div>
        {/await}
    </div>
    <div class="row">
        <a class="chip medium round" href="https://t.me/volgatech_app">
            <Telegram/>
            {$_('page.info.telegramNews', {default: 'Telegram News'})}
        </a>
        <button class="border" data-ui="#share" on:click={{}}>
            <MIcon>share</MIcon>
            {$_('page.info.share', {default: 'Share'})}
        </button>
    </div>
    <article class="round secondary-container no-elevate"
             style="padding: 8px; margin-left: -8px; margin-right: -8px; border-radius: 40px 40px 0 0;">
        <!--        <article class="round">-->
        <!--            <nav class="right-align">-->
        <!--                <UpdateMeButton/>-->
        <!--            </nav>-->
        <!--        </article>-->

        <article
            class="round surface no-elevate"
            style="padding: 16px;"
            on:click={() => navigate("/education")}
            on:keydown
        >
            <nav>
                <button class="square round extra fill">
                    <MIcon>leaderboard</MIcon>
                </button>
                <h6>{$_("page.info.education", { default: "Education" })}</h6>
                <div class="max"></div>
                <button class="circle extra surface-container">
                    <MIcon>arrow_forward</MIcon>
                </button>
            </nav>
        </article>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <article class="round surface no-elevate" style="padding: 16px;"
                 on:click={() => window.location.href = '/settings'}>
            <nav>
                <button class="square round extra fill">
                    <MIcon>settings</MIcon>
                </button>
                <h6>{$_('page.settings.nav', {default: 'Settings'})}</h6>
                <div class="max"></div>
                <button class="circle extra surface-container">
                    <MIcon>arrow_forward</MIcon>
                </button>
            </nav>
        </article>

        <article class="round surface no-elevate">
            <nav>
                <button class="square round extra fill">
                    <MIcon>system_update_alt</MIcon>
                </button>
                <h6>{$_('updateButton.update', {default: 'Update'})}</h6>
                <div class="max"></div>
                <UpdateMeButton compact={true}/>
            </nav>
        </article>
        <article class="round surface">
            <h5>{$_('page.info.author', {default: 'About author'})}</h5>
            <p>{$_('page.info.authorDescription', {default: 'This web app is the result of countless hours of creativity and coffee, brought to life by @FreedomCoder'})}</p>
            <nav>
                <a class="chip round" href="https://donatty.com/freedomcoder">
                    <Heart/>
                    <span>{$_('page.info.donate', {default: 'Donate'})}</span>
                </a>
                <a class="chip round" href="https://t.me/FreedomCoder">
                    <Telegram/>
                    <span>Telegram</span>
                </a>
            </nav>
        </article>
        <div class="row" style="justify-content: space-between;">
            <nav data-ui="#about" class="left-align">
                <button class="transparent" on:click={{}}>
                    <MIcon>info</MIcon>
                    {$_('page.info.aboutApp', {default: 'About app'})}
                </button>
            </nav>
            <nav class="right-align">
                <button class="border no-border" on:click={logout}>
                    <MIcon class="error-text">logout</MIcon>
                    <span class="error-text">{$_('page.info.logout', {default: 'Logout'})}</span>
                </button>
            </nav>
        </div>
    </article>
</Navbar>