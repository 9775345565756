<script>
    import {fade} from 'svelte/transition';
    import {_} from "svelte-i18n";
    import MIcon from "./MIcon.svelte";
    import Volga from '../res/Volga.svelte';
    import {_icon_literal} from "../utils/icon.js";
    import {APP_VERSION} from "../config.js";
    import {isPWAInstalled} from "../utils/platform.js";

    const isMobile = ((window.innerWidth > 0) ? window.innerWidth : screen.width) <= 600;    
    const clientInfo = {};
    //clientInfo[_icon_literal("info")] = APP_VERSION;
    clientInfo[_icon_literal("devices")] = navigator.platform;
    clientInfo[_icon_literal("language")] = navigator.language;
    clientInfo[_icon_literal("width")] = window.innerWidth;
    clientInfo[_icon_literal("height")] = window.innerHeight;
    clientInfo[_icon_literal("deployed_code")] = isPWAInstalled() ? 'Standalone' : 'Webpage';
</script>

<dialog class={isMobile ? "bottom" : ""} id="about">
    <div transition:fade|local>
        <nav>
            <h5 class="max">{$_('aboutSheet.title', {default: 'About app'})}</h5>
            <button class="circle transparent" data-ui="#about">
                <MIcon>close</MIcon>
            </button>
        </nav>
        <div class="row vertical">
            <div class="center-align">
                <Volga/>
                <div class="vertical middle-align">
                    <h6>Volga App</h6>
                    <div class="chip no-border">
                        <i class="primary-text" translate="no" class:notranslate={true}>{_icon_literal("info")}</i>
                        Version {APP_VERSION}  
                    </div>
                </div>
            </div>

            <nav class="wrap center-align">
                {#each Object.entries(clientInfo) as [key, value]}
                    <div class="chip">
                        <i class="primary-text" translate="no" class:notranslate={true}>{key}</i>
                        <span>{value}</span>
                    </div>
                {/each}
            </nav>
        </div>
    </div>
</dialog>
