
export const APP_VERSION = '1.3.2';
export const API_URL = 'https://api.volgatech.net/api';

export const THEME_COLORS = {
    "Red": "#F44336",
    "Pink": "#E91E63",
    "Purple": "#9C27B0",
    "Deep Purple": "#673AB7",
    "Indigo": "#3F51B5",
    "Blue": "#2196F3",
    "Light Blue": "#03A9F4",
    "Cyan": "#00BCD4",
    "Teal": "#009688",
    "Default": "#4CAF50",
    "Light Green": "#8BC34A",
    "Lime": "#CDDC39",
    "Yellow": "#FFEB3B",
    "Amber": "#FFC107",
    "Orange": "#FF9800",
    "Deep Orange": "#FF5722",
    "Brown": "#795548",
    "Blue Grey": "#607D8B",
    "Grey": "#9E9E9E"
}