<script>
    import Navbar from "../components/Navbar.svelte";
    import MIcon from "../components/MIcon.svelte";
    import {login} from "../utils/api.ts";
    import {navigate} from "svelte-routing";
    import {_} from "svelte-i18n";
    import {isAuthenticated} from '../stores.ts';

    if ($isAuthenticated) {
        setTimeout(() => {
            navigate('/', {replace: true});
        }, 0);
    }

    export let location;
    let loginValue, passwordValue;
    let error, submitting;

    async function authorize() {
        submitting = true;
        try {
            await login(loginValue, passwordValue)
            navigate("/")
        } catch (err) {
            error = err;
        } finally {
            submitting = false;
        }
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        authorize();
    }
</script>

<Navbar zenMode={true}>
    <form on:submit={handleFormSubmit}>
        <article>
            <h5 style="padding-bottom: 24px">{$_('page.login.title', {default: 'Sign in to Volgatech'})}</h5>
            <div class="field label border">
                <input type="text" aria-label="login" autocomplete="nickname" bind:value={loginValue}>
                <label>{$_('page.login.login', {default: 'Login'})}</label>
            </div>
            <div class="field label border">
                <input type="password" aria-label="password" autocomplete="current-password" bind:value={passwordValue}>
                <label>{$_('page.login.password', {default: 'Password'})}</label>
            </div>
            <nav style="justify-content: flex-end;">
                <button type="submit">
                    <MIcon>login</MIcon>
                    <span>{$_('page.login.signIn', {default: 'Sign in'})}</span>
                </button>
            </nav>
        </article>
    </form>
    {#if error}
        <div class="row">
            <i class="orange-text" translate="no">warning</i>
            <div class="max">{$_('error', {default: 'Error: '})}{error.message}</div>
        </div>
    {/if}

    {#if submitting}
        <div class="overlay">
            <progress class="circle large"></progress>
        </div>
    {/if}
</Navbar>