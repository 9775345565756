<script>
    export let size = 128;
</script>

<svg width={size} height={size} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="8.75">
      <path stroke="#2F99D5" d="m7.785 60.92 28.852 28.852m-6.206-.019 60.528-60.978"/>
      <path stroke="#fff" d="m22.201 60.92 28.852 28.852m-6.206-.019 60.512-60.994"/>
      <path stroke="#CF261F" d="M36.618 60.92 65.47 89.772m-6.206-.019 60.7-60.806"/>
    </g>
</svg>
  