<script>
    import {Route, Router} from "svelte-routing";
    import Index from "./routes/Index.svelte";
    import LoginGuardedRoute from "./components/LoginGuardedRoute.svelte";
    import Login from "./routes/Login.svelte";
    import Info from "./routes/Info.svelte";
    import {chosenTheme, theme} from "./stores.ts";
    import {setColorTheme, updateBodyClass} from "./utils/theme.js";
    import {THEME_COLORS} from "./config.js";
    import Settings from "./routes/Settings.svelte";
    import Education from "./routes/Education.svelte";

    export let url = "";

    $: (async () => {
        await updateBodyClass($theme);
        await setColorTheme(THEME_COLORS[$chosenTheme])
    })()
</script>

<Router url="{url}">
    <LoginGuardedRoute path="info" component="{Info}"/>
    <LoginGuardedRoute path="education" component="{Education}"/>
    <LoginGuardedRoute path="settings" component="{Settings}"/>
    <Route path="login" component="{Login}"/>
    <LoginGuardedRoute path="/" component="{Index}"/>
</Router>