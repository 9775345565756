<script>
    import {_} from "svelte-i18n";
    import MIcon from "./MIcon.svelte";
    import {subgroupFilter} from "../stores.ts";

    const isMobile = ((window.innerWidth > 0) ? window.innerWidth : screen.width) <= 600;    
</script>

<style>
    div.row {
        width: 100;
        padding: 0.5rem;
        padding-bottom: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        transition: opacity var(--speed3);
    }
    div.row:last-child {
        padding-bottom: 0.5rem;
    }
    div.row[disabled=true] {
        pointer-events: none;
        opacity: 0.5;
    }
</style>

<dialog class={isMobile ? "bottom" : ""} id="my-subgroup">
    <nav>
        <h5 class="max">{$_('mySubgroupSheet.title', {default: 'Subgroup settings'})}</h5>
        <button class="circle transparent" data-ui="#my-subgroup">
            <MIcon>close</MIcon>
        </button>
    </nav>
    <div class="row">
        <div class="max">
            <h6>{$_('mySubgroupSheet.enabledFilter', {default: 'Enable filter'})}</h6>
        </div>
        <label class="switch">
            <input type="checkbox" bind:checked="{$subgroupFilter.filterEnabled}">
            <span></span>
        </label>
    </div>
    <div class="row" disabled={!$subgroupFilter.filterEnabled}>
        <h6>{$_('mySubgroupSheet.subgroupNumber', {default: 'Subgroup number'})}</h6>
        <div class="field suffix border">
            <select bind:value={$subgroupFilter.subgroupNumber}>
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
            <i>arrow_drop_down</i>
        </div>
    </div>
    <div class="row" disabled={!$subgroupFilter.filterEnabled}>
        <h6>{$_('mySubgroupSheet.filterType', {default: 'Filter type'})}</h6>
        <div class="field suffix border">
            <select bind:value={$subgroupFilter.filterType}>
                <option value="exclude">{$_('mySubgroupSheet.excludeFilterType', {default: 'Exclude'})}</option>
                <option value="translucent">{$_('mySubgroupSheet.translucentFilterType', {default: 'Translucent'})}</option>
            </select>
            <i>arrow_drop_down</i>
        </div>
    </div>
</dialog>
