<script>
    import {navigate, Route} from 'svelte-routing';
    import {isAuthenticated} from '../stores.ts';

    $: if (!$isAuthenticated) {
        console.log("[LoginGuardedRoute] navigate login", $isAuthenticated)
        setTimeout(() => {
            navigate('/login', {replace: true});
        }, 100);
    }

</script>

<Route {...$$props} let:params>
    {#if $isAuthenticated}
        <slot {params}/>
    {/if}
</Route>