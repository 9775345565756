<script>
    import MIcon from "../components/MIcon.svelte";
    import Navbar from "../components/Navbar.svelte";
    import { _ } from "svelte-i18n";
    import {
        apiGetSemesters,
        apiGetSemesterProgress,
        apiGetExams,
    } from "../utils/api.ts";
    import Grades from "../components/Grades.svelte";
    import ExamDetails from "../components/ExamDetails.svelte";

    let selectedSemester = null;
    let semesterProgress = null;
    let examDetails = null;
    let loadingProgress = false;
    let error = null;

    let semestersPromise = apiGetSemesters()
        .then((semesters) => {
            if (semesters.length > 0) {
                selectedSemester = semesters[0].numSemestr;
                loadEducation();
            }
            return semesters;
        })
        .catch((err) => {
            error = err;
            console.error(err);
        });

    async function loadEducation() {
        loadingProgress = true;
        try {
            semesterProgress = await apiGetSemesterProgress(selectedSemester);
            examDetails = await apiGetExams(selectedSemester);
        } catch (err) {
            error = err;
            console.error(err);
        } finally {
            loadingProgress = false;
        }
    }
</script>

<Navbar
    pageName={$_("page.education.nav", { default: "Education" })}
    canReturn={true}
>
    {#await semestersPromise}
        <progress></progress>
    {:then semesters}
        <div class="field suffix border">
            <select bind:value={selectedSemester} on:change={loadEducation}>
                {#each semesters as sem}
                    <option value={sem.numSemestr}
                        >{sem.blockName + " " + sem.numSemestr}</option
                    >
                {/each}
            </select>
            <i>arrow_drop_down</i>
        </div>
        {#if loadingProgress}
            <progress></progress>
        {:else if error}
            <div class="row">
                <MIcon class="orange-text">warning</MIcon>
                <div class="max">
                    {$_("error", { default: "Error: " })}{error.message}
                </div>
            </div>
        {:else}
            {#if examDetails}
                <ExamDetails {examDetails} />
            {/if}
            {#if semesterProgress}
                <Grades {semesterProgress} />
            {/if}
        {/if}
    {:catch error}
        <div class="row">
            <MIcon class="orange-text">warning</MIcon>
            <div class="max">
                {$_("error", { default: "Error: " })}{error.message}
            </div>
        </div>
    {/await}
</Navbar>
