import './app.css';
import App from './App.svelte';
import './cdn/beer.ts'
import './cdn/beer.css'
import './i18n.js'
import './pwa.ts'
import "material-dynamic-colors";

// if (!import.meta.VITE_BUILD_DEV) {
//     console.log("Production build detected.");
//     ym(94983291, "init", {
//         clickmap: true,
//         trackLinks: true,
//         accurateTrackBounce: true,
//         webvisor: false
//     });
// }

const app = new App({
    target: document.getElementById('app'),
});

export default app;
