<script>
    import { _ } from "svelte-i18n";
    import MIcon from "./MIcon.svelte";

    export let semesterProgress = [];
</script>

<article class="round">
    <nav>
        <h4 class="primary-text">
            {$_("page.education.grades.title", { default: "Your grades" })}
        </h4>
    </nav>
    {#each semesterProgress as entry (entry.subjectName)}
        <article class="s12 m6 l3">
            <nav class="top-align">
                <h6 class="max">{entry.subjectName}</h6>
                <h6 class="primary-text">
                    {#if entry.markValue > 0}
                        {#if entry.markValue == 6}
                            <MIcon>done</MIcon>
                        {:else}
                            {entry.markValue}
                        {/if}
                    {:else}
                        {entry.totalBalls}
                    {/if}
                </h6>
            </nav>
            <nav class="wrap">
                {#if entry.markValue == 0}
                    {#each entry.atts as att}
                        <div class="chip fill">
                            <span class="primary-text"
                                >{$_("page.education.grades.attestation", {
                                    default: "Attestation",
                                })}
                                {att.numAttestation}
                            </span>
                            <span
                                >{att.attBall} ({att.minBall} - {att.maxBall})</span
                            >
                            {#if att.minBall > att.attBall}
                                <MIcon class="error-text">error</MIcon>
                            {:else}
                                <MIcon class="green-text">done</MIcon>
                            {/if}
                        </div>
                    {/each}
                {/if}
            </nav>
        </article>
    {/each}
</article>
