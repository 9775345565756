<script>
    import {slide} from 'svelte/transition';
    import {onDestroy, onMount} from 'svelte';
    import {scheduleCache} from '../stores.ts';
    import {convertTimeReadable, dateDifferenceInSeconds, formatTime, isCurrentDay} from "../utils/dates.js";
    import {get} from "svelte/store";
    import {_} from "svelte-i18n";

    let currentSchedule = null;
    let currentEvent = null;
    const updateCurrentEvent = () => {

        const now = new Date();
        // now.setHours(15, now.getMinutes() + 23, now.getSeconds(), 0);

        const today = now.toISOString().split('T')[0];

        currentSchedule = $scheduleCache?.find((schedule) => isCurrentDay(schedule.date, now));

        if (currentSchedule) {

            if (currentSchedule.events.length > 0) {

                currentEvent = null;
                let prevTime = null;
                for (let i = 0; i < currentSchedule.events.length; i++) {
                    const event = currentSchedule.events[i];
                    if (event.wrongSubgroup) 
                        continue;
                    
                    const startTime = new Date(`${today}T${event.timeBegin}`);
                    const endTime = new Date(`${today}T${event.timeEnd}`);

                    if (now >= startTime && now <= endTime)
                        currentEvent = event;
                    else if (i === 0 && startTime > now) {
                        console.log('Start of the day');
                        currentEvent = {
                            "timeBegin": null,
                            "timeEnd": null,
                            "description": $_('eventBar.state.start', {default: 'None remaining'}),
                        }
                    } else if (i === currentSchedule.events.length - 1 && endTime < now) {
                        console.log('End of the day');
                        currentEvent = {
                            "timeBegin": null,
                            "timeEnd": null,
                            "description": $_('eventBar.state.end', {default: 'None remaining'}),
                        }
                    } else if (prevTime && startTime > now && new Date(`${today}T${prevTime}`) < now) {
                        console.log('Recess');
                        currentEvent = {
                            "timeBegin": prevTime,
                            "timeEnd": event.timeBegin,
                            "description": $_('eventBar.state.break', {default: 'Break'}) + " > " + event.description,
                            "isRecess": true
                        }
                    }
                    prevTime = event.timeEnd;
                }

                if (currentEvent) {
                    if (currentEvent.timeEnd) {
                        let [hours, minutes] = currentEvent.timeEnd.split(':').map(Number);
                        const dateEnd = new Date();
                        dateEnd.setHours(hours, minutes, 0, 0);
                        [hours, minutes] = currentEvent.timeBegin.split(':').map(Number);
                        const dateStart = new Date();
                        dateStart.setHours(hours, minutes, 0, 0);
                        currentEvent.secondsTotal = dateDifferenceInSeconds(dateStart, dateEnd)
                        currentEvent.secondsRemain = dateDifferenceInSeconds(now, dateEnd)
                        currentEvent.secondsFrmt = formatTime(currentEvent.secondsRemain)
                    }
                    console.log('Current event found:', currentEvent);
                } else {
                    console.log('No current event found');
                }
            }
        } else {
            console.log('No current schedule found');
        }
    };

    let intervalId;

    onMount(() => {
        updateCurrentEvent();
        intervalId = setInterval(updateCurrentEvent, 1000);

        window.addEventListener('focus', updateCurrentEvent);
    });

    onDestroy(() => {
        clearInterval(intervalId);
        window.removeEventListener('focus', updateCurrentEvent);
    });
</script>

{#if currentEvent && currentEvent.timeBegin}
    <!--    <DualstateView value={0.15} style="margin: 0 -1rem;">-->
    <div class="row" style="margin: 0px;" transition:slide|local>
        {#if currentEvent.timeBegin}
            <div>
                <h5 class="small">{convertTimeReadable(currentEvent.timeBegin)}</h5>
                <h5 class="small">{convertTimeReadable(currentEvent.timeEnd)}</h5>
            </div>
        {/if}
        <h5 class="s small primary-text max {currentEvent.isRecess || 'secondary-text'}" style="text-overflow: ellipsis ellipsis;">{currentEvent.description}</h5>
        <h5 class="m l primary-text max {currentEvent.isRecess || 'secondary-text'}" style="text-overflow: ellipsis ellipsis;">{currentEvent.description}</h5>
        {#if currentEvent.secondsFrmt}
            <h5>{currentEvent.secondsFrmt}</h5>
        {/if}
    </div>
    {#if currentEvent.timeBegin}
        <progress class="full-width {currentEvent.isRecess || 'secondary-text'}" value={currentEvent.secondsTotal - currentEvent.secondsRemain}
                  max={currentEvent.secondsTotal} transition:slide|local></progress>
    {:else}
        <p>{$_('eventBar.error.noEvents', {default: 'None ongoing events'})}</p>
    {/if}
    <!--    <nav slot="filled" class="full-width secondary-container">-->
    <!--        <div>-->
    <!--            <h5 class="small">{convertTimeReadable(currentEvent.timeBegin)}</h5>-->
    <!--            <h5 class="small">{convertTimeReadable(currentEvent.timeEnd)}</h5>-->
    <!--        </div>-->
    <!--        <h5 class="max primary-text">{currentEvent.description}</h5>-->
    <!--        <h5>{currentEvent.secondsFrmt}</h5>-->
    <!--    </nav>-->
    <!--    </DualstateView>-->
<!--{:else}-->
<!--    <p>{$_('eventBar.error.noEvents', {default: 'None ongoing events'})}</p>-->
{/if}

<style>
    .full-width {
    }

    @media only screen and (max-width: 600px) {
        .full-width {
            margin: 0 -1rem;
            width: 100vw;
        }
    }

</style>