<script>
    import { _ } from "svelte-i18n";
    import {
        convertDateReadable,
        convertTimeReadable,
    } from "../utils/dates.js";

    export let examDetails = [];

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error(`Invalid date: ${dateString}`);
            return;
        }
        return `${convertDateReadable(date, "none")}, ${convertTimeReadable(date.toTimeString())}`;
    }
</script>

<article class="round">
    <nav>
        <h4 class="primary-text">
            {$_("page.education.exams.title", { default: "Exam schedule" })}
        </h4>
    </nav>
    {#each examDetails as entry (entry.subjectName)}
        <article class="s12 m6 l3">
            <nav class="top-align">
                <h6 class="max">{entry.subjectName}</h6>
            </nav>
            <nav class="wrap">
                <div class="chip fill">
                    <span class="primary-text">
                        {$_("page.education.exams.date", { default: "Date" })}
                    </span>
                    <span>
                        {entry.examDate
                            ? formatDateTime(entry.examDate)
                            : $_("page.education.exams.undefined", {
                                  default: "Undefined",
                              })}
                    </span>
                </div>

                <div class="chip fill">
                    <span class="primary-text">
                        {$_("page.education.exams.location", {
                            default: "Location",
                        })}
                    </span>
                    <span>
                        {entry.roomName
                            ? `${entry.roomName} (${entry.buildingName})`
                            : $_("page.education.exams.undefined", {
                                  default: "Undefined",
                              })}
                    </span>
                </div>

                <div class="chip fill">
                    <span class="primary-text">
                        {$_("page.education.exams.teacher", {
                            default: "Teacher",
                        })}
                    </span>
                    <span>
                        {entry.fio ||
                            $_("page.education.exams.undefined", {
                                default: "Undefined",
                            })}
                    </span>
                </div>
            </nav>
        </article>
    {/each}
</article>
