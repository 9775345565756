<script>
    import Navbar from "../components/Navbar.svelte";
    import MIcon from "../components/MIcon.svelte";
    import {discoveredSubgroups, preferences, scheduleCache, subgroupFilter, customSchedule} from "../stores.ts";
    import {apiGetSchedule} from "../utils/api.ts";
    import {convertDateReadable, getWeekDay, isCurrentDay} from "../utils/dates.js";
    import { compareEvents, sortScheduleDay } from "../utils/events";
    import {_} from "svelte-i18n";
    import {get} from "svelte/store";
    import { onDestroy, onMount } from "svelte";
    import EditScheduleDayModal from "../components/EditScheduleDayModal.svelte";
    import ScheduleEvent from "../components/ScheduleEvent.svelte";

    let error, loading;
    let startOfWeek, endOfWeek;
    let weekOffset = 0;
    let today = new Date();
    let activeEditingSchuleDay = null;
    let updateSchedule = false;

    let scrolledToPos = false;

    function scrollToCurrentDay() {
        if (scrolledToPos) {
            return;
        }

        const currentDayElement = document.getElementById('current-day');
        if (currentDayElement) {
            currentDayElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            scrolledToPos = true;
        }
    }

    function filterSchedule(schedule) {
        const subgroupNumber = String($subgroupFilter.subgroupNumber);

        for (let i = 0; i < schedule.length; i++) {
            for (let j = 0; j < schedule[i].events.length; j++) {
                const lesson = schedule[i].events[j];

                if (lesson.subGroup && !lesson.subGroup.endsWith(subgroupNumber)) {
                    switch ($subgroupFilter.filterType) {
                        case "exclude": {
                            schedule[i].events.splice(j, 1);
                            break;
                        }
                        case "translucent": {
                            schedule[i].events[j].wrongSubgroup = true;
                            break;
                        }
                    }
                }
            }        
        }
    }

    function loadCustomSchedule(schedule) {
        schedule.forEach((scheduleDay) => {
            // load disabled events
            scheduleDay.events.forEach((event) => {
                console.log(event);
                if ($customSchedule.deletions.some((e) => compareEvents(event, e))) {
                    event.disabled = true;
                }
            })

            // load custom events
            const weekTypeName = scheduleDay?.events[0].weekTypeName;
            const customEventsForToday = $customSchedule.insertions.filter((e) => weekTypeName === e.weekTypeName && getWeekDay(new Date(e.date)) === getWeekDay(new Date(scheduleDay.date)));
            scheduleDay.events = [...scheduleDay.events, ...customEventsForToday];

            sortScheduleDay(scheduleDay);
        });
    }

    async function loadData() {
        loading = true

        startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + weekOffset * 7);

        endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - today.getDay()) + weekOffset * 7);

        try {
            const freshData = await apiGetSchedule(startOfWeek, endOfWeek);
            if ($subgroupFilter.filterEnabled)
                filterSchedule(freshData);

            loadCustomSchedule(freshData);

            scheduleCache.set(freshData);
            let discoveredSubgroupsSet = new Set(get(discoveredSubgroups))
            // TODO
            // freshData.forEach(e => e.events.forEach() discoveredSubgroupsSet.add(e.))
        } catch (e) {
            console.error('Error fetching fresh data:', e);
            error = e;
        }
        loading = false
        if ($preferences.preferAutoScrollToToday)
            setTimeout(scrollToCurrentDay, 300);
    }

    export function moveWeekOffset(offset) {
        weekOffset += offset;
        loadData();
    }

    loadData();

    let focusHandler;

    onMount(() => {
        focusHandler = () => {
            if (!isCurrentDay(today)) {
                today = new Date();
                loadData();
            }
        };
        window.addEventListener("focus", focusHandler);
    });

    onDestroy(() => {
        window.removeEventListener("focus", focusHandler);
    });
</script>

<style>
    .schedule-day {
        margin-block-start: 0rem;
        margin-block-end: 1rem;
    }
</style>

<Navbar loading={loading} pageName="{$_('page.schedule.nav', {default: 'Schedule'})}">
    <EditScheduleDayModal scheduleDay={activeEditingSchuleDay} bind:updateSchedule={updateSchedule}/>

    <nav class="middle-align">
        <button class="circle transparent" on:click={() => moveWeekOffset(-1)}>
            <MIcon>arrow_back</MIcon>
        </button>
        <span>{startOfWeek.toLocaleDateString('ru-RU')} - {endOfWeek.toLocaleDateString('ru-RU')}</span>
        <button class="circle transparent" on:click={() => moveWeekOffset(1)}>
            <MIcon>arrow_forward</MIcon>
        </button>
    </nav>
    {#key updateSchedule}
    {#if $scheduleCache && $scheduleCache.length > 0}
        <div class="grid">
            {#each $scheduleCache as schedule}
                <article class="schedule-day round border s12 m6 l6" class:secondary-container={isCurrentDay(schedule.date)}
                         id={isCurrentDay(schedule.date) ? 'current-day' : ''}>
                    <h6 class="surface-variant center-align" style="position: relative; padding: 0 2.5rem;">
                        {convertDateReadable(new Date(schedule.date))}
                        <button class="circle transparent small" style="position: absolute; right: 0;" data-ui="#editScheduleDay"
                            on:click={() => {activeEditingSchuleDay = schedule}}>
                            <MIcon>edit</MIcon>
                        </button>
                    </h6>
                    {#each schedule.events as event, index}
                        <ScheduleEvent event={event} />
                    {/each}
                </article>
            {/each}
        </div>
    {:else if $scheduleCache && $scheduleCache.length === 0}
    <div class="center-align" style="display: flex">
        <h6>{$_('page.schedule.empty', {default: 'There are no classes this week'})}</h6>
    </div>
    {/if}
    {#if error}
        <div class="row">
            <MIcon class="orange-text">warning</MIcon>
            <div class="max">{$_('error', {default: 'Error: '})}{error.message}</div>
        </div>
    {/if}
    {/key}
</Navbar>