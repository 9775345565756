import {locale} from "svelte-i18n";
import {get} from "svelte/store";

export function isCurrentDay(date, today = new Date()) {
    const inputDate = new Date(date);
    return (
        inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
    );
}

export function convertDateReadable(date, weekday= 'long') {
    const currentLocale = get(locale); // Get the current value of the locale store
    const options = { weekday: weekday === 'none' ? undefined : weekday , month: 'long', day: 'numeric' };

    return date.toLocaleDateString(currentLocale, options);
}

export function getWeekDay(date) {
    const currentLocale = get(locale); // Get the current value of the locale store
    return date.toLocaleString(currentLocale, { weekday: "long" })
}

export function convertTimeReadable(time) {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
}

export function dateDifferenceInSeconds(date1, date2) {
    const millisecondsDifference = Math.abs(date1 - date2);
    const secondsDifference = Math.floor(millisecondsDifference / 1000);
    return secondsDifference;
}

export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (seconds >= 3600) {
        return `${hours}h ${minutes}m`;
    } else if (seconds >= 900) {
        return `${minutes}m`;
    } else {
        return `${minutes}m ${remainingSeconds}s`;
    }
}