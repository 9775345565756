import {Writable, writable} from 'svelte/store';

function persistentWritable<T>(key: string, initialValue: T): Writable<T> {
    const storedValue = localStorage.getItem(key);
    const parsedValue = storedValue === null ? initialValue : JSON.parse(storedValue);
    console.log("[persistentWritable] Load", key, parsedValue)
    const store = writable<T>(parsedValue);

    store.subscribe((value) => {
        console.log("[persistentWritable] Store", key, value)
        if (value)
            localStorage.setItem(key, JSON.stringify(value));
        else
            localStorage.removeItem(key);
    });

    return store;
}

export default persistentWritable;