<script>
    import {updatePending} from "../stores.ts";
    import UpdateMeButton from "./UpdateMeButton.svelte";
    import {_} from "svelte-i18n";

    let showUpdateDialog = false
    $: if ($updatePending) showUpdateDialog = true
</script>

<dialog class:active={showUpdateDialog}>
    <h5>{$_('prompt.update.title', {default: 'Update?'})}</h5>
    <div>{$_('prompt.update.description', {default: 'Refresh page and start enjoying new features!'})}</div>
    <nav class="right-align">
        <button class="border" on:click={() => {showUpdateDialog = false}}>{$_('prompt.update.cancel', {default: 'Cancel'})}</button>
        <UpdateMeButton/>
    </nav>
</dialog>