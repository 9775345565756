<script>
    import {chosenTheme} from "../stores.ts";
    import {THEME_COLORS} from "../config.js";
    import MIcon from "./MIcon.svelte";
    import ThemeSwitch from "./ThemeSwitch.svelte";
    import {_} from "svelte-i18n";
</script>


<article class="round">
    <nav>
        <h4 class="primary-text">{$_('page.settings.theme.title', {default: 'Theme'})}</h4>
        <div class="max"></div>
        <ThemeSwitch/>
    </nav>
    <nav class="wrap">
        <!--        <button-->
        <!--                class="round square extra border"-->
        <!--                on:click={() => {$chosenTheme = null}}-->
        <!--        >-->
        <!--            {#if !$chosenTheme}-->
        <!--                <MIcon>done</MIcon>-->
        <!--            {/if}-->
        <!--        </button>-->
        {#each Object.entries(THEME_COLORS) as [name, color]}
            <button
                    class="round square extra"
                    style="background-color: {color}"
                    on:click={() => {$chosenTheme = name}}
            >
                {#if $chosenTheme === name}
                    <MIcon class="extra">done</MIcon>
                {/if}
                <div class="tooltip">{name}</div>
            </button>
        {/each}
    </nav>
</article>