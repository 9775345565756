<script>
    import {fade} from 'svelte/transition';
    import {_} from "svelte-i18n";
    import MIcon from "./MIcon.svelte";

    function loadQRCode() {
        new QRCode(document.getElementById("qrcode"), {
            text: "https://volga.fcd.su?r=qr",
            colorDark: "#000000",
	        colorLight:"#ffffff",
            correctLevel: QRCode.CorrectLevel.H
        });
    }

    async function share() {
        const shareData = {
            title: "Volgatech",
            text: "Volgatech Schedule",
            url: "https://volga.fcd.su?r=share",
        };

        try {
            await navigator.share(shareData);
        }
        catch (e) {
            console.error("Can't share URL:", e)
        }
    }
</script>

<svelte:head>
	<script src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js" on:load={loadQRCode}></script>
</svelte:head>

<dialog id="share">
    <div transition:fade|local>
        <nav>
            <h5 class="max">{$_('shareSheet.title', {default: 'Share'})}</h5>
            <button class="circle transparent" data-ui="#share">
                <MIcon>close</MIcon>
            </button>
        </nav>
        <div class="no-round row vertical">
            <div class="border white-border" style="border-width: 0.5rem;" id="qrcode"/>
            <button class="border" on:click={() => share()}>
                <MIcon>share</MIcon>
                {$_('shareSheet.shareWith', {default: 'Share with'})}
            </button>
        </div>
    </div>
</dialog>
