<script>
    import {slide} from 'svelte/transition';
    import "../helpers/fontelevate.css";
    import {links} from "svelte-routing";
    import {_} from 'svelte-i18n'
    import MIcon from "./MIcon.svelte";
    import "../app.css";
    import UpdateMe from "./UpdateMe.svelte";
    import EventBar from "./EventBar.svelte";
    import '@khmyznikov/pwa-install';

    let dark = false
    let route = window.location.pathname.toString()
    let isHome = route === "/"
    let isSettings = !isHome

    function isMobile() {
        const isMobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const isTouchable = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        const isDesktop = (window.screenX != 0 && !isTouchable) || (window.devicePixelRatio === 1 && screen.width > 600);

        return isMobileAgent || !isDesktop;
    }

    export let pageName = undefined;
    export let canReturn = false;
    export let zenMode = false;
    export let loading = false;
    export let onReload = undefined;

</script>

{#if isMobile()}
    <pwa-install
            manifest-url="./manifest.webmanifest"
            description={$_('PWANotify.appDescription', {default: 'Drop-in replacement for a volgatech mobile app'})}
            install-description={$_('PWANotify.installDescription', {default: 'Install app for faster and easier access'})}>
    </pwa-install>
{/if}

{#if !zenMode}
    <nav class="no-select left m l" use:links>
        <div class="max"></div>
        <a href="/" class:active={isHome}>
            <MIcon>calendar_today</MIcon>
            <div>{$_('page.schedule.nav', {default: 'Schedule'})}</div>
        </a>
        <a href="info" class:active={isSettings}>
            <MIcon>info</MIcon>
            <div>{$_('page.info.nav', {default: 'Info'})}</div>
        </a>
        <div class="max"></div>
    </nav>

    <nav class="no-select bottom s">
        <a href="/" class:active={isHome}>
            <MIcon>calendar_today</MIcon>
        </a>
        <a href="info" class:active={isSettings}>
            <MIcon>info</MIcon>
        </a>
    </nav>
{/if}

<header class="no-select responsive fixed surface">
    <nav>
        {#if canReturn}
            <button class="circle transparent" on:click={() => window.history.back()}>
                <MIcon>arrow_back</MIcon>
            </button>
        {/if}
        {#if pageName} <h5>{pageName}</h5>{/if}
        <div class="max"></div>
        {#if onReload !== undefined}
            <button class="circle transparent" on:click={onReload}>
                <MIcon>refresh</MIcon>
            </button>
        {/if}
        <a class="circle transparent" href="https://gitlab.fcd.su/fcdapps/volga-app/">
            <img class="responsive" src="/favicon.svg" alt="logo">
        </a>
    </nav>

    {#if loading}
        <progress transition:slide|local></progress>
    {/if}

    {#if !zenMode}
        <EventBar/>
    {/if}
</header>

<UpdateMe/>

<main class="no-select responsive">
    <slot></slot>
</main>