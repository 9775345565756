import { getWeekDay } from "./dates";

export function compareEvents(event1, event2) {
    const propertiesToCompare = [
        'building', 
        'description', 
        'fio', 
        'room', 
        'subGroup',
        'timeBegin', 
        'timeEnd', 
        'typeWorkName', 
        'weekTypeName'
    ];


    return propertiesToCompare.every(prop => event1[prop] === event2[prop]) &&
        getWeekDay(new Date(event1.date)) === getWeekDay(new Date(event2.date));
}

export function sortScheduleDay(scheduleDay) {
    scheduleDay.events.sort((a,b) => 
        (a.timeBegin.split(':')[0]*60 + a.timeBegin.split(':')[1]) - 
        (b.timeBegin.split(':')[0]*60 + b.timeBegin.split(':')[1]));
}