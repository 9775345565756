<script>
    import { getWeekDay } from "../utils/dates";
    import { compareEvents, sortScheduleDay } from "../utils/events";
    import {fade} from 'svelte/transition';
    import {_} from "svelte-i18n";
    import MIcon from "./MIcon.svelte";
    import ScheduleEvent from "./ScheduleEvent.svelte";
    import { customSchedule } from "../stores";

    export let scheduleDay;
    export let updateSchedule;

    let addingEvent = false;
    $: weekTypeName = scheduleDay?.events[0].weekTypeName;
    $: weekColor = weekTypeName === "Синяя неделя" ? "blue-text" : "red-text";
    
    let description = "", fio = "", building = "", room = "", timeBegin = "00:00", timeEnd = "00:00", typeWorkName;
    let invalidTime = false, invalidSubject = false;

    function isTimeRangeValid(start, end) {
        if (!timeBegin || !timeEnd) {
            return false;
        }

        const beginHours = start.split(':')[0];
        const beginMinutes = start.split(':')[1];

        const endHours = end.split(':')[0];
        const endMinutes = end.split(':')[1];

        return !(beginHours > endHours ||
            beginHours === endHours && beginMinutes > endMinutes);
    }

    function saveNewEvent() {
        if (!isTimeRangeValid(timeBegin, timeEnd)) {
            invalidTime = true;
        }

        if (!description) {
            invalidSubject = true;
        }

        if (invalidTime || invalidSubject) {
            return;
        }

        const event = {
            id: (+new Date).toString(),
            building,
            date: scheduleDay.date,
            description,
            fio,
            room,
            timeBegin,
            timeEnd,
            typeWorkName,
            weekTypeName
        }

        $customSchedule.insertions = [...$customSchedule.insertions, event];
        scheduleDay.events = [...scheduleDay.events, event];
        sortScheduleDay(scheduleDay);

        addingEvent = false;
        description = fio = building = room = "";
        timeBegin = timeEnd = "00:00";
        updateSchedule = !updateSchedule;
    }

    function editEventStatus(event, deleteCustom=false) {
        if (event.id) {
            if (deleteCustom) {
                $customSchedule.insertions = $customSchedule.insertions.filter((e) => e.id !== event.id);
                scheduleDay.events = scheduleDay.events.filter((e) => e.id !== event.id);
            } else {
                const eventIndex = $customSchedule.insertions.findIndex((e) => e.id === event.id);
                $customSchedule.insertions[eventIndex].disabled = !$customSchedule.insertions[eventIndex].disabled;
            }
        }
        else if (event.disabled) {
            // enable default event
            $customSchedule.deletions = $customSchedule.deletions.filter((e) => !compareEvents(e, event));
            event.disabled = false;
        }
        else if (!event.disabled) {
            // disable default event
            event.disabled = true;
            $customSchedule.deletions = [...$customSchedule.deletions, event];
        }

        updateSchedule = !updateSchedule;
    }
</script>

<dialog id="editScheduleDay">
    <div transition:fade|local>
        {#if scheduleDay}
        {#key updateSchedule}
            <nav>
                <h5 class="max {weekColor}" style="text-transform: capitalize;">{getWeekDay(new Date(scheduleDay.date))}</h5>
                <button class="circle transparent" data-ui="#editScheduleDay">
                    <MIcon>close</MIcon>
                </button>
            </nav>

            {#each scheduleDay.events as event}
            <div class="row">
                <div class="vertical">
                    <button class="circle transparent large" disabled={event.wrongSubgroup} on:click={() => editEventStatus(event, false)}>
                        <MIcon class="large">{event.disabled ? "undo" : "close"}</MIcon>
                    </button>
                    {#if event.id}
                    <button class="circle transparent large" on:click={() => editEventStatus(event, true)}>
                        <MIcon class="large red-text">delete</MIcon>
                    </button>
                    {/if}
                </div>

                <div class="round border padding max">
                    <ScheduleEvent event={event} />
                </div>
            </div>
            {/each}

            <!--New Event Button-->
            {#if !addingEvent}
            <div class="row max">
                <button class="circle large hidden"/> <!-- Align button to event div -->
                <button class="transparent border large" on:click={() => {addingEvent = true}}>
                    <MIcon class="large">calendar_add_on</MIcon>
                    <h5 class="padding">{$_('customScheduleSheet.newEvent', {default: 'Add'})}</h5>
                </button>
            </div>
            {/if}

            <!--New Event Fields-->
            {#if addingEvent}
            <div class="row max">
                <div class="vertical" style="gap: 1rem;">
                    <button class="circle transparent border large" on:click={saveNewEvent}>
                        <MIcon class="large green-text">save</MIcon>
                    </button>
                    <button class="circle transparent large" on:click={() => {addingEvent = false}}>
                        <MIcon class="large">close</MIcon>
                    </button>
                </div>
                <div class="vertical round border padding max" style="gap: 0.5rem;">
                    <div class={"field label border " + (invalidSubject ? "invalid" : "")}>
                        <input type="text" on:change={() => {invalidSubject = false}} bind:value={description}>
                        <label>{$_('customScheduleSheet.subject', {default: 'Subject'})}</label>
                    </div>

                    <div class="field label border">
                        <input type="text" bind:value={fio}>
                        <label>{$_('customScheduleSheet.teacher', {default: 'Teacher'})}</label>
                    </div>

                    <div class="field label suffix border">
                        <select bind:value={typeWorkName}>
                            <option value="Лекции">{$_('customScheduleSheet.lecture', {default: 'Lecture'})}</option>
                            <option value="Лабораторные">{$_('customScheduleSheet.laboratory', {default: 'Laboratory'})}</option>
                            <option value="Практические">{$_('customScheduleSheet.practical', {default: 'Practical'})}</option>
                        </select>
                        <label>{$_('customScheduleSheet.type', {default: 'Type'})}</label>
                        <i>arrow_drop_down</i>
                    </div>

                    <div class="row no-margin">
                        <div class="field label max">
                            <input type="text" bind:value={building}>
                            <label>{$_('customScheduleSheet.building', {default: 'Building'})}</label>
                        </div>
                        <div class="field label max">
                            <input type="text" bind:value={room}>
                            <label>{$_('customScheduleSheet.room', {default: 'Room'})}</label>
                        </div>
                    </div>
                    
                    <div class="row no-margin wrap">
                        <div class={"field label max " + (invalidTime ? "invalid" : "")}>
                            <input type="time" on:change={() => {invalidTime = false}} bind:value={timeBegin}>
                            <label class="active">{$_('customScheduleSheet.begin', {default: 'Begin'})}</label>
                        </div>

                        <div class={"field label max " + (invalidTime ? "invalid" : "")}>
                            <input type="time" on:change={() => {invalidTime = false}} bind:value={timeEnd}>
                            <label class="active">{$_('customScheduleSheet.end', {default: 'End'})}</label>
                        </div>
                    </div>
                </div>
            </div>
            {/if}
        {/key}
        {/if}   
    </div>
</dialog>
