import {Schedule, StudentDescriptor, UserCredentials, UserSession, SubgroupFilter, CustomSchedule} from "./utils/models";
import persistentWritable from "./utils/persitentWritable";
import {writable} from "svelte/store";

export const theme = persistentWritable<number>('themeOption', 0);

const DEFAULT_SETTINGS = {
    preferAutoScrollToToday: true
}
export const preferences = persistentWritable<typeof DEFAULT_SETTINGS>('preferences', DEFAULT_SETTINGS);

export const updatePending = writable<boolean>( false);
export const offlineReady = writable<boolean>( false);

export const chosenTheme = persistentWritable<string | null>('chosenTheme', "Default");

export const isAuthenticated = persistentWritable<boolean>('isAuthenticated', false);
export const userCredentials = persistentWritable<UserCredentials | null>('userCredentials', null);
export const session = persistentWritable<UserSession | null>('session', null);
export const studentDescriptor = persistentWritable<StudentDescriptor | null>('studentDescriptor', null);
export const scheduleCache = persistentWritable<Schedule[] | null>('scheduleCache', null);
export const discoveredSubgroups = persistentWritable<string[]>('discoveredSubgroups', []);

const DEFAULT_SUBGROUP_FILTER: SubgroupFilter = {
    filterEnabled: false,
    filterType: "translucent",
    subgroupNumber: "1"
}
export const subgroupFilter = persistentWritable<SubgroupFilter>('subgroupFilter', DEFAULT_SUBGROUP_FILTER);

const DEFAULT_CUSTOM_SCHEDULE: CustomSchedule = {
    // in "insertions" will be ONLY custom created events
    // in "deletions" will be ONLY default disabled events
    insertions: [], 
    deletions: []
}
export const customSchedule = persistentWritable<CustomSchedule>('customSchedule', DEFAULT_CUSTOM_SCHEDULE);