<script>
    import Navbar from "../components/Navbar.svelte";
    import {apiGetStudentInfo, logout} from "../utils/api.ts";
    import MIcon from "../components/MIcon.svelte";
    import {APP_VERSION} from "../config.js";
    import UpdateMeButton from "../components/UpdateMeButton.svelte";
    import ThemePicker from "../components/ThemePicker.svelte";
    import MySubgroupModal from "../components/MySubgroupModal.svelte";
    import {preferences, customSchedule} from "../stores.ts";
    import {_icon_literal} from "../utils/icon.js";
    import {_} from "svelte-i18n";
    // import tg from "../res/telegram.svg";
    import Telegram from "../res/Telegram.svelte";

    const __unused = _icon_literal("check")

    let scheduleImporter;
    let ip;
    let error = null;
    let submitting = false;

    async function submit() {
        submitting = true;
        try {
            // await set_settings(ip);
        } catch (err) {
            error = err;
        } finally {
            submitting = false;
        }
    }

    function exportSchedule() {
        const exporter = document.createElement('a');
        exporter.href = 'data:attachment/text,' + encodeURI(JSON.stringify($customSchedule));
        exporter.target = '_blank';
        exporter.download = 'customSchedule.json';
        exporter.click();
    }

    async function importSchedule(file) {
        try {
            const text = await file.text();
            const json = JSON.parse(text);

            if (!Array.isArray(json.insertions) || !Array.isArray(json.deletions)) {
                throw new Error("Invalid schedule format: 'insertions' or 'deletions' not found");
            }

            customSchedule.set(json);
        } catch (error) {
            console.error(error);
        }
    }

    const dataPromise = apiGetStudentInfo();
    let clientInfo = {};
    clientInfo[$_('page.info.clientInfo.version', {default: 'App version'})]= APP_VERSION;
    clientInfo[$_('page.info.clientInfo.platform', {default: 'Platform'})]= navigator.platform;
    clientInfo[$_('page.info.clientInfo.language', {default: 'Language'})]= navigator.language;
    clientInfo[$_('page.info.clientInfo.width', {default: 'Screen width'})]= window.innerWidth;
    clientInfo[$_('page.info.clientInfo.height', {default: 'Screen height'})]= window.innerHeight;

</script>

<Navbar pageName={$_('page.settings.nav', {default: 'Settings'})} canReturn={true}>
    <ThemePicker/>
    <MySubgroupModal/>
    <article class="round">
        <div class="field">
            <nav class="no-padding">
                <div class="max">
                    <h6>{$_('page.settings.autoScroll', {default: 'Auto scroll today'})}</h6>
                    <div>{$_('page.settings.autoScrollDescription', {default: 'Scroll to today automatically'})}</div>
                </div>
                <label class="switch">
                    <input type="checkbox" bind:checked="{$preferences.preferAutoScrollToToday}">
                    <span></span>
                </label>
            </nav>
        </div>
        <div class="field">
            <nav class="no-padding" data-ui="#my-subgroup">
                <div class="max">
                    <h6>{$_('page.settings.mySubgroups', {default: 'My subroups'})}</h6>
                    <div>{$_('page.settings.mySubgroupsDescription', {default: 'Filter schedule for your subgroup'})}</div>
                </div>
                <button>
                    <MIcon>edit</MIcon>
                    {$_('page.settings.mySubgroupsChange', {default: 'Change'})}
                </button>
            </nav>
        </div>
        <div class="field" style="height: max-content;">
            <nav class="no-padding">
                <div class="max">
                    <h6>{$_('page.settings.customSchedule', {default: 'Custom Schedule'})}</h6>
                    <div>{$_('page.settings.customScheduleDescription', {default: 'Export and import custom schedule'})}</div>
                </div>
                <div class="row max wrap right-align">
                    <button on:click={exportSchedule}>
                        <MIcon>download</MIcon>
                        {$_('page.settings.exportSchedule', {default: 'Export'})}
                    </button>
                    <button on:click={scheduleImporter.click()}>
                        <MIcon>upload_file</MIcon>
                        {$_('page.settings.importSchedule', {default: 'Import'})}
    
                        <input
                            type="file"
                            style="display: none;"
                            accept="application/json"
                            bind:this={scheduleImporter}
                            on:change={(event) => {
                                const files = event.currentTarget.files;
                                if (files && files.length > 0) importSchedule(files.item(0));
                                scheduleImporter.value = "";
                            }}
                        />
                    </button>
                </div>
            </nav>
        </div>
    </article>
</Navbar>