import {theme} from "../stores.ts";
import {get} from "svelte/store";

const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

export async function setColorTheme(color) {
    if (!color) return;
    await ui("theme", color);
}

export async function updateBodyClass() {
    switch (get(theme)) {
        case 0:
            await setDarkMode(prefersDarkScheme.matches);
            break;
        case 1:
            await setDarkMode(false);
            break;
        case 2:
            await setDarkMode(true);
            break;
    }
}

async function setDarkMode(isDark) {
    await ui("mode", isDark ? "dark" : "light");
    document.querySelector(':root').style.setProperty('color-scheme', isDark ? "dark" : "light");
}

prefersDarkScheme.addEventListener('change', () => {
    if (get(theme) === 0) {
        updateBodyClass().then();
    }
});