
export function isPWAInstalled() {
    // For iOS
    if (window.navigator.standalone) return true;

    // For Android and others
    if (window.matchMedia('(display-mode: standalone)').matches) return true;

    // Default return for browsers
    return false;
}