<script>
    import {chosenTheme, theme} from "../stores.ts";
    import {_icon_literal} from "../utils/icon.js";
    import {setColorTheme, updateBodyClass} from "../utils/theme.js";

    function themeSwitch() {
        $theme = ($theme + 1) % 3;
    }
</script>

<button class="circle transparent" on:click={themeSwitch}>
    <i class:notranslate={true} translate="no">{(() => {
        switch ($theme) {
            case 1:
                return _icon_literal("light_mode");
            case 2:
                return _icon_literal("dark_mode");
            case 0:
                return _icon_literal("night_sight_auto");
            default:
                return "";
        }
    })()}</i>
</button>