import {API_URL} from '../config.js';
import {isAuthenticated, session, studentDescriptor, userCredentials} from '../stores';
import {get} from 'svelte/store';
import {
    ErrorResponse,
    ExamInfo,
    ProgressInfo,
    Schedule,
    SemesterInfo,
    StudentDescriptor,
    StudentInfo,
    UserSession
} from "./models";

export const undefinedDate = "0001-01-01T00:00:00+03:00";

export async function login(email, password) {
    try {
        const userData = await apiLogin(email, password);
        session.set(userData);
        const descriptor = await apiGetProfiles()
        userCredentials.set({"login": email, password})
        isAuthenticated.set(true);
        studentDescriptor.set(descriptor[0]);
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
}

export async function logout() {
    userCredentials.set(null);
    studentDescriptor.set(null);
    session.set(null);
    isAuthenticated.set(false);
}

export async function refreshAuth() {
    try {
        const userData = await apiRefreshAuth();
        isAuthenticated.set(true);
        session.set(userData);
    } catch (error) {
        let gatheredUserCredentials = get(userCredentials);
        if (gatheredUserCredentials) {
            try {
                await login(gatheredUserCredentials.login, gatheredUserCredentials.password)
            } catch (error) {
                throw Error('Automatic reauth failed:' + error.toString());
            }
        } else {
            await logout()
            throw Error('Token refresh failed:' + error.toString());
        }
    }
}

class ApiError extends Error {
    errorResponse: ErrorResponse;

    constructor(errorResponse: ErrorResponse) {
        super("API: " + errorResponse.ErrorDescription);
        this.errorResponse = errorResponse;
    }
}

async function apiCall(endpoint: string, method: string, body?: object): Promise<any> {
    const userSession = get(session);
    const headers = makeHeaders(userSession ? userSession.accessToken : null);
    const requestBody = body ? JSON.stringify(body) : null;

    const response = await fetch(`${API_URL}${endpoint}`, {
        method,
        headers,
        body: requestBody,
    });

    const responseBody = await response.json();

    console.log(`Request: ${method} ${API_URL}${endpoint}\nRequest Body: ${requestBody}\nResponse: ${response.status} ${response.statusText}\nResponse Body: `, responseBody);

    if (!response.ok) {
        let error = responseBody
        if (error.hasOwnProperty("status"))
            throw new Error("API: " + (error.message ?? JSON.stringify(error)))
        if (error.hasOwnProperty("Error"))
            if (error.Error === "invalid_token") {
                await refreshAuth()
                return apiCall(endpoint, method, body)
            } else
                throw new ApiError(error);
    }

    return responseBody;
}

function makeHeaders(token: string | null): HeadersInit {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
}

export async function apiGetSchedule(startDate: Date, endDate: Date): Promise<Schedule[]> {
    const student = get(studentDescriptor);
    return apiCall('/Calendar/' + student.studentId + '/' + formatDateForApi(startDate) + '/' + formatDateForApi(endDate) + '/' + 'TimeTable,Event,Private', 'GET');
}

export async function apiGetStudentInfo(): Promise<StudentInfo> {
    const student = get(studentDescriptor);
    return apiCall('/Student/GetInfo/' + student.studentId, 'GET');
}

export async function apiGetSemesters(): Promise<SemesterInfo[]> {
    const student = get(studentDescriptor);
    return apiCall('/CourseSemestrs/' + student.studentId, 'GET');
}

export async function apiGetSemesterProgress(semesterId: number): Promise<ProgressInfo[]> {
    const student = get(studentDescriptor);
    return apiCall('/ProgressSemestr/GetProgressSemestrWithAtt/' + student.studentId + "/" + semesterId, 'GET');
}

export async function apiGetExams(semesterId: number): Promise<ExamInfo[]> {
    const student = get(studentDescriptor);
    const result = await apiCall('/Exams/' + student?.studentId + '/' + semesterId, 'GET');
    return result.map(item => {
        for (let key in item) {
            if (item[key] === undefinedDate) {
                item[key] = null;
            }
        }
        return item;
    });
}

export async function apiGetProfiles(): Promise<StudentDescriptor[]> {
    return apiCall('/Student/GetProfiles', 'GET');
}

export async function apiLogin(login: string, password: string): Promise<UserSession> {
    return apiCall('/Auth/GetToken', 'POST', {login, password});
}

export async function apiRefreshAuth(): Promise<UserSession> {
    const userSession = get(session);
    return apiCall('/Auth/RefreshToken', 'POST', {"refreshToken": userSession.refreshToken});
}

let padStart = function (str, token, len) {
    return token.repeat(len - str.length) + str;
};

let formatDateForApi = function (date: Date) {
    if (typeof (date) == 'number') {
        date = new Date(date);
    }
    return date.getFullYear() + '-' + padStart((date.getMonth() + 1) + '', '0', 2) + '-' + padStart(date.getDate() + '', '0', 2);
};