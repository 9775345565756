<script>
    import {updateSW} from "../pwa.ts";
    import MIcon from "./MIcon.svelte";
    import {updatePending} from "../stores.ts";
    import {_} from "svelte-i18n";

    let updating = false;
    export let compact = false;
</script>

{#if compact}
    {#if $updatePending}
        {#if updating}
            <button class="fill extra circle">
                <MIcon>sync</MIcon>
            </button>
        {:else}
            <button class="fill extra circle" on:click={() => {updating = true; updateSW(true)}}>
                <MIcon>download</MIcon>
            </button>
        {/if}
    {:else}
        <button class="surface-container circle extra" on:click={() => {updateSW(true)}}>
            <MIcon>done</MIcon>
        </button>
    {/if}
{:else}
    {#if $updatePending}
        {#if updating}
            <button class="secondary">
                <MIcon>sync</MIcon>
                <span>{$_('updateButton.updating', {default: 'Updating...'})}</span>
            </button>
        {:else}
            <button on:click={() => {updating = true; updateSW(true)}}>
                <MIcon>sync</MIcon>
                <span>{$_('updateButton.update', {default: 'Update'})}</span>
            </button>
        {/if}
    {:else}
        <button class="border" on:click={() => {updateSW(true)}}>
            <MIcon>done</MIcon>
            <span>{$_('updateButton.latest', {default: 'Latest version'})}</span>
        </button>
    {/if}
{/if}